
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import { PIXCAP_PRIVACY_LINK, PIXCAP_TERMS_LINK } from '@pixcap/ui-core/constants/app.constants';
	import SocialAuthLogin from '@pixcap/ui-core/components/account/authentication/SocialAuthLogin.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';
	import IconGoogle from '@pixcap/ui-library/components/Icons/IconGoogle.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	@Component({
		components: { Button, IconGoogle, HeadingText, CaptionText, BodyText, PixcapLogo, SocialAuthLogin },
	})
	export default class LoginOptions extends Vue {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		PIXCAP_PRIVACY_LINK = PIXCAP_PRIVACY_LINK;
		PIXCAP_TERMS_LINK = PIXCAP_TERMS_LINK;
		loginWithEmail() {
			this.$emit('onLoginWithEmail');
		}
	}
